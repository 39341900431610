<template>
	<div>
		<div
            v-html="question.html"
		></div>
	</div>
</template>


<script type="text/javascript">
export default {
	name: "Html",
	props: {
		id: {},
	},
	computed: {
        question(){
            return this.$store.state.questionTemplates.data[this.id]
        }
    },
};
//
</script>
"
